<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px" height="50%">
      <v-card>
        <!-- <v-card-title class="text-h5">
          Use Google's location service?
        </v-card-title> -->
        <v-card-text>
          <div class="content d-flex align-center justify-center">
            <p class="">
              {{ text }}
            </p>
          </div>
        </v-card-text>
        <v-card-actions class="text-center py-4">
          <v-btn class="main-btn mx-auto" to="/MasterData">{{
            $t("Go to Settings")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "generalModal",
  props: ["text", "dialog"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.content {
  min-height: 200px;
  p {
    font-size: 1.5rem;
    line-height: 1.5;
  }
}
</style>
