<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackTime"
      :color="snackColor"
      :multi-line="multiline"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">
        {{ snackMsg }}
      </span>

      <v-btn text @click="close">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "snackComponent",
  props: {
    snackbar: {
      type: Boolean,
      default: false,
    },
    snackMsg: {
      type: String,
      default: "",
    },
    snackTime: {
      type: Number,
      default: 2000,
    },
    snackColor: {
      type: String,
      default: "red",
    },
    multiline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("closeSnack");
    },
  },
};
</script>

<style></style>
